@keyframes shake {
    0%, 100% {
      transform: translateX(0);
    }
    10%, 30%, 50%, 70%, 90% {
      transform: translateX(-5px);
    }
    20%, 40%, 60%, 80% {
      transform: translateX(5px);
    }
}

.first-background-container {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    width: 100vw;
    height: 90vh;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 1000px;
    overflow-x: hidden;
    overflow-y: hidden;
}

    .nav-bar-container {
        width: 100vw;
        height: 10vh;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 10px 20px;
    }

    .title {
        font-size: 1.2rem;
        color: white;
        margin-right: auto;
        padding-left: 5vw;
    }

    .nav-bar {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        color: black;
        gap: 10px;
        margin-right: 5vw;
    }

    .nav-bar-button {
        display: inline-block;
        padding: 10px 20px;
        background-color: #333;
        color: #fff;
        border-radius: 5px;
        text-decoration: none;
        font-size: 16px;
        transition: background-color 0.3s ease;
    }

    .nav-bar-button:hover {
        background-color: #555;
    }

    .nav-bar-button:focus {
        outline: none;
        background-color: #555;
    }

    .nav-bar-button:active {
        background-color: #777;
    }

    .first-content-container {
        display: flex;
        flex-direction: row;
    }

    .first-content-left {
        width: 40vw;
        height: 80vh;
        display: flex;
        flex-direction: column;
        margin-top: 10vh;
        align-items: center;
    }

    .first-content-left-img {
        width: 30vw;
        height: auto;
        border-radius: 50%; /* if you use a square image, it will be a round one here. */
        transition: 1s;
    }

    .first-content-left-title {
        max-width: 40vw;
        margin-top: 5vh;
        font-size: 5rem;
        font-weight: bold;
        color: black;
    }

    .first-content-left-text {
        max-width: 40vw;
        margin-top: 5vh;
        font-size: 3rem;
        font-weight: bold;
        color: black;
    }

    .first-content-left-img:hover {
        transform: scale(1.1);
    }

    .first-content-right {
        width: 60vw;
        height: 80vh;
    }

.second-background-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 90vh;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
}

    .second-content-top {
        width: 100vw;
        height: 45vh;
        display: flex;
        flex-direction: row;
    }

    .second-content-top-left {
        width: 50vw;
        height: 45vh;
        position: relative;
    }

    .second-content-top-left-img {
        width: 45vw;
        max-height: 40vh;
        margin-top: 2.5vh;
        margin-left: auto;
        margin-right: auto;
    }

    .img-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #000000;
        font-weight: bold;
        font-size: 1.5rem;
        text-align: center;
        padding: 10px;
        border-radius: 5px; 
        max-width: 80%;
        z-index: 1;
    }

    .second-content-top-right {
        width: 50vw;
        height: 45vh;
    }

    .second-content-top-right-img {
        width: 40vw;
        max-height: 40vh;
        margin-top: 2.5vh;
        margin-left: auto;
        margin-right: auto;
    }

    .second-content-bottom {
        width: 100vw;
        height: 45vh;
    }

    .second-content-bottom-box-container {
        width: 92vw;
        height: 40vh;
        margin: auto;
        display: flex;
        flex-direction: column;
        transition: 0.5s;
    }

    .second-content-bottom-box-container:hover {
        transform: scale(1.05);
    }

    .second-content-bottom-box-title {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }

    .second-content-bottom-box-img-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 5vh;
    }

    .second-content-bottom-box-img {
        width: 50vw;
        max-height: 30vh;
    }

.third-background-container {
    width: 100vw;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    color: #000;
    font-size: 1rem;
    background-repeat: no-repeat;
    background-size: cover;
}